/* Reset and Base Styles */
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
a{
    text-decoration: none;
}
body {
    scroll-behavior: smooth;
    font-family: 'Roboto', sans-serif;
}

:root {
    --primary: #FF8FAB;
    --secondary: #FFB3C6;
    --light: #FFC2D1;
}
.additionalFunction a{
    display: flex !important; 
    text-decoration: none !important;
    color: black !important;
    justify-content: center;
}
#phNumber, #mailTo{
    text-decoration: none;
    color: black;
}
header {
    width: 100%;
    height: auto !important;
}
.mainPanelRight{
    display: inline-grid;
}
/* Navigation Styles */
.nav1 {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    box-shadow: 2px 0px 3px var(--primary);
    text-align: center;
}
.black-link {
    color: black;
  }
.nav1 .left {
    flex-basis: 50%;
    letter-spacing: 2px;
}

.nav1 .left a, .nav1 .left .logout-button {
    text-decoration: none;
    color: var(--primary);
    font-weight: bold;
    margin: 0px 5px;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.nav1 .left .logout-button:hover {
    color: var(--secondary);
}

.right {
    flex-basis: 50%;
}

.right i {
    font-size: 20px;
    margin: 0px 15px;
    background-color: var(--primary);
    color: white;
    padding: 8px;
    cursor: pointer;
    transition: 0.4s ease-in-out;
}

.nav1 .right i:hover {
    transform: rotate(360deg);
}
.cart-icon {
    position: relative;
    /* other styles for the cart icon */
  }
  .cart-notification-dot {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: red;
    border-radius: 50%;

    right: 25%;

    transform: translate(50%, -50%);
    /* Adjust top, right, and transform values as needed */
  }
  
.nav2 {
    width: 100%;
    height: 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    text-align: center;
}

.nav2 .nav2-left, .nav2 .nav2-center, .nav2 .nav2-right {
    flex-basis: 30%;
    margin: auto;
    cursor: pointer;
}

.nav2 .nav2-left i {
    margin-left: 15px;
}

.nav2 .nav2-center h1 {
    color: var(--primary);
    font: 2rem;
    letter-spacing: 5px;
    font-weight: bold;
}

.nav2 .nav2-center h1 span {
    color: var(--light);
}

.nav2 .nav2-center p {
    color: grey;
    font-size: 15px;
    margin-top: 5px;
}

.nav2 .nav2-right i {
    margin: 0 5px;
}

.nav3 {
    width: 100%;
    height: 70px;
    line-height: 70px;
}

.nav3 ul {
    list-style: none;
    text-align: center;
}

.nav3 ul li {
    display: inline-block;
    width: 150px;
    position: relative;
    z-index: 99;
}

.nav3 ul li a {
    text-decoration: none;
    color: black;
    font: 500;
    display: block;
}

.nav3 ul li a:hover {
    color: var(--primary);
}

.nav3 ul li i {
    color: var(--primary);
    margin: 0px 5px;
}

.nav3 ul ul {
    position: absolute;
    top: 70px;
    left: 0;
    display: none;
}

.nav3 ul ul li {
    background-color: white;
    border-bottom: 1px solid var(--primary);
}

.nav3 ul li:hover > ul {
    display: block;
}
.panel.right-panel {
    width: 400px;
    max-width: 100%;
    overflow: hidden; /* or overflow: auto; depending on your design */
  }
  .search-container {
    display: flex;
    flex-direction: column;
    margin-top: 10px; /* Adjust as needed */
    z-index: 100;
    /* Add more styling as needed */
  }
  
  .sr-main {
    display: flex;
    justify-content: center; /* Centers horizontally */
    align-items: center; /* Centers vertically */
    height: 100vh; /* Full height of the viewport */
    width: 100%; /* Full width */
  }
  #searchInput {
    display: flex;
    width: 50%;
    margin: 10px;
    align-self: center;
    justify-content: center;
    align-items: center;
    }
    .sr-container {
        position: absolute;

        left: 50%; /* Center the container */
        transform: translateX(-50%); /* Ensure it's centered regardless of width */
        width: 100%; /* Full width */
        max-width: 600px; /* Maximum width of the dropdown */
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        z-index: 100; /* Make sure it's above other content */
      }
      
  .search-results-dropdown {
    position: absolute;
    width: 100%; /* Full width of the sr-main container */
    max-width: 600px; /* Maximum width of the dropdown */
    margin-top: 20px; /* Give some space from the search input */
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden; /* Allows us to apply border-radius on children */
    align-self: center;
  }
  .search-result-item {
    padding: 10px 12px;
    border-bottom: 1px solid #eee; /* subtle separator */
    cursor: pointer;
    background-color: #fff; /* Default background */
    transition: background-color 0.2s;
  }
  
  
  .search-result-item:last-child {
    border-bottom: none;
  }
  
  .search-result-item:hover {
    background-color: #f7f7f7; /* Highlight on hover */
  }
  