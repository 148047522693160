/* Add this to your stattionary.css or a global CSS file */
.main-content-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* This ensures your content takes at least the full viewport height */
    justify-content: space-between; /* This pushes the footer to the bottom */
  }
 /* product-detail.css */

:root {
    --primary: #FF8FAB;
    --secondary: #FFB3C6;
    --light: #FFC2D1;
  }
  
  .product-detail-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%; /* Take the full width of its parent */
    padding: 2rem;
    box-sizing: border-box; /* Make sure padding does not add to the width */
  }
  .product-card {
    background-color: #fff; /* White background */
    width: 100%; /* Full width of the container */
    max-width: 800px; /* Maximum width of the card */
    margin: 1rem;
    padding: 2rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    border-radius: 8px; /* Rounded corners */
    display: flex;
    flex-direction: column; /* Stack children vertically */
    align-items: center; /* Center children horizontally */
  }
  .product-card img {
    width: 100%; /* Full width of the card */
    max-width: 300px; /* Maximum width of the image */
    height: auto; /* Keep aspect ratio */
    margin-bottom: 1rem;
  }
  
  .product-image img {
    max-width: 100%;
    height: auto;
  }
  
  .product-info {
    padding: 1rem;
    width: 50%;
    display: flex;
    flex-direction: column;
  }
  
  .product-title {
    color: #333;
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }
  
  .product-descriptionDetail {
    font-size: 1rem;
    color: #666;
    line-height: 1.4;
    margin-bottom: 1rem;
  }
  
  .price {
    color: var(--primary);
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .rating {
    color: #ffc107;
    margin-bottom: 1rem;
  }
  
  .add-to-cart-btn {
    background-color: var(--primary);
    color: white;
    border: none;
    padding: 10px 16px;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .add-to-cart-btn:hover {
    background-color: var(--secondary);
  }
 
  /* Additional styles for responsiveness and aesthetics */
  @media (max-width: 768px) {
    .product-detail-container {
      flex-direction: column;
    }
  
    .product-image, .product-info {
      width: 100%;
    }
  }
  