
  .order-item {
    border: 1px solid #ddd;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .product-item {
    display: flex;
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #f0f0f0;
    border-radius: 4px;
  }
  
  .product-item:not(:last-child) {
    border-bottom: none;
  }
  
  .product-image {
    width: 80px;
    height: 80px;
    object-fit: cover;
    margin-right: 15px;
    border-radius: 4px;
  }
  
  .product-details {
    flex-grow: 1;
  }
  
  .product-details h3 {
    margin: 0;
    color: #333;
    font-size: 1.2em;

  }
  
  .product-details p {
    margin: 5px 0;
    color: #666;
    text-align: start;
  }
  
  .product-price {
    color: #E45641;
    font-weight: bold;
    font-size: medium;
    margin-top: 10px;
  }
  
  /* If you have a button or link to view more details, you can style it like this: */
  .order-item button {
    background-color: #E45641;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 0.9em;
    margin-top: 10px;
  }
  
  .order-item button:hover {
    background-color: #C7442A;
  }
  /* Popup Styles */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s;
}
#csH2{
  color: black;
}
.popup-content {
  background-color: var(--light);
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.popup-show {
  visibility: visible;
  opacity: 1;
}

.popup-button {
  background-color: var(--primary);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.popup-button:hover {
  background-color: var(--secondary);
}

  /* Responsive adjustments */
  @media (max-width: 768px) {
    .product-item {
      flex-direction: column;
      align-items: center;
    }
  
    .product-image {
      margin-bottom: 10px;
    }
  
    .product-details h3 {
      text-align: center;
    }
  
    .product-details p {
      text-align: center;
    }
  }
  