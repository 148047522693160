* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  scroll-behavior: smooth;
  font-family: 'Roboto', sans-serif;
}

:root {
  --primary: #FF8FAB;
  --secondary: #FFB3C6;
  --light: #FFC2D1;
}

.main-content-container-sp {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* This ensures your content takes at least the full viewport height */
    justify-content: space-between; /* This pushes the footer to the bottom */
  }

.product-intro {
  text-align: center;
}


#content-h {
  color: var(--primary);
  justify-content: center;
  text-align: center;
  letter-spacing: 0.2rem;
  font-size: 2rem;
  font-weight: bolder;
}

#content-h span {
  color: var(--light);
}
  .modal-stationary {
    border: 1px solid;
    border-radius: 5px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    z-index: 1000;

    }
    .cart {
      padding: 20px;
      background-color: #fff;
      border-radius: 4px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
    
    .cart h2 {
      font-size: 24px;
      margin-bottom: 20px;
    }
    
  
    
    .cart-items {
      border-top: 1px solid #ddd;
    }
    
    .cart-item {
      display: flex;
      align-items: center;
      padding: 20px 0;
      border-bottom: 1px solid #ddd;
    }
    
    .item-image img {
      width: 100px;
      margin-right: 20px;
    }
    
    .item-details {
      flex-grow: 1;
    }
    
    .item-price {
      color: var(--primary);
      font-size: 18px;
      font-weight: bold;
      margin: 10px 0;
    }
    
    .item-actions {
      display: flex;
      align-items: center;
    }
    
    .quantity-selector select {
      padding: 5px 10px;
      color: var(--primary);
      font-size: 16px; /* Increase font size for better visibility */
      border: 1px solid #ddd; /* Optional: adds a border to the select box */
      border-radius: 4px; /* Optional: rounds the corners of the select box */
      -webkit-appearance: none; /* Removes default styling on iOS */
      -moz-appearance: none; /* Removes default styling on Firefox */
      appearance: none; /* Removes default arrow for a custom dropdown */
      background: url('data:image/svg+xml;charset=US-ASCII,<svg width="12" height="12" viewBox="0 0 4 5" xmlns="http://www.w3.org/2000/svg"><path fill="%23666" d="M2 0L0 2h4z"/></svg>') no-repeat right 5px center; /* Adds custom dropdown arrow */
      background-size: 12px; /* Size of the custom arrow */
      cursor: pointer; /* Changes the cursor to indicate it's a dropdown */
      width: auto; /* Adjust width as needed */
      min-width: 75px; /* Ensures the dropdown isn't too small */
      box-shadow: inset 0 1px 2px rgba(0,0,0,.075); /* Optional: adds inset shadow to the dropdown */
      margin-right: 10px;
    }
    
    .delete-btn, .save-for-later-btn {
      background: none;
      border: 1px solid #ddd;
      color: var(--primary);
      cursor: pointer;
      padding: 5px 10px;
      margin-right: 10px;
    }
    
    .cart-summary {
      text-align: right;
      margin-top: 20px;
      font-size: 18px;
      font-weight: bold;
    }
    